
.slider {
  width: 100%;
  padding: 4rem 0;

  h2 {
    text-align: center;
    margin-bottom: 50px;
    font-family: "Avenir Next", sans-serif;
  }

    .slider-container {
    display: flex;
    overflow: hidden;
    margin-bottom: 10px;

    .slider-item-wrapper {
      flex: 0 0 calc(25% - 10px);
      margin-right: 40px;
      transition: transform 0.3s ease-in-out;

    }

        .slider-item {
      flex: 0 0 calc(25% - 10px);
      margin-right: 40px;
      transition: transform 0.3s ease-in-out;
     
 }

      .sizesContainer {
        display: flex;
        gap: 5px; 
        text-align: center;
        justify-content: center;

      }

      p{
        font-size: 2rem;
        font-family: "Avenir Next", sans-serif;
        text-align: center;
        margin-bottom: 5px;

      }

      .size {
        display: inline-block;
        margin-right: 5px; 
        padding: 1rem; 
        color: #000; 
        font-size: 1.5rem;
          }

      .selected {
        background-color: #00f; 
        color: #fff; 
      }




      img {
        width:  300px;
        height: 300px;
        object-fit: cover;
                border-radius: 8px;

    
      }
      @media screen and (max-width: 768px) {
        img {
          width: 200px;
          height: 200px;
          object-fit: cover;
                  border-radius: 8px;
  
      
        }
        p{
          font-size: 0.85rem;
          font-family: "Avenir Next", sans-serif;

        }
        .slider-item{
          margin-right: 60px;
        }
        .size{
          font-size: 0.85rem;
          font-family: "Avenir Next", sans-serif;
          padding: 0.8; 


        }
  
      }

      @media screen and (max-width: 480px) {
        img {
          width:  150px;
          height: 150px;
          object-fit: cover;
                  border-radius: 8px;
  
      
        }
  
      }


    }
  }




     

     .slider-controls {
    position: relative;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-700%);
    pointer-events: none;

    button {
      font-size: 24px;
      background: transparent;
      border: none;
      cursor: pointer;
      transition: color 0.3s;


    }

    .prev {

      top: 50%;
      transform: translateY(-50%);
      pointer-events: all;
      left: 0;
      padding: 8px 0px;
      color: black;
    }

    .next {

      top: 50%;
      transform: translateY(-50%);
      pointer-events: all;
      right: 0;
      padding: 8px 0px;
      color: black;
    }


 }

    @media screen and (max-width: 768px) {
      .slider{
        padding: 2rem 0;

        .slider-container{
          .slider-item-wrapper{
            flex: 0 0 calc(10% - 10px);
            margin-right: 20px;
      
          }
          .slider-item{
            margin-right: 10px;
          }
        }
        img {
          width: 150px;
          height: 150px;
        }


      }
    }


  
      

    @media screen and (max-width: 768px) {
      .slider-controls {
        transform: translateY(-400%);
        position: relative;
        top: 50%;
    
      }
      .prev {
        top: 30%;
        transform: translateY(-20%);
        font-family: "Avenir Next", sans-serif;
  
      }
      .next{
        top: 30%;
        transform: translateY(-20%);
        

      }
    } 
