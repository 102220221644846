
.table {
  padding: 5px;
  width: 90%;
  overflow-x: auto;
  margin: 0 auto;

  .empt{
    text-align: center;
    margin:0 auto;
  }
  .empt p{
    font-size: 2.5rem;
  }
  .empt a{
    font-size: 2.5rem;

  }

  .empt img {
    width: 400px;
    height: 400px;
  }


  table {
    border-collapse: collapse;
    width: 100%;
    font-size: 1.4rem;


    thead {
      border: 0 solid #e5e7eb;
    }

    th {
      border: 1px solid #eee;
    }

    th,
    td {
      vertical-align: top;
      text-align: left;
      // word-wrap: break-word;

      padding: 8px;
      &.icons {
        > * {
          margin-right: 5px;
          cursor: pointer;
        }
      }
    }

    tr {
      border: 1px solid #e5e7eb;
    }

    tr:nth-child(even) {
      // background-color: #eee;
    }
  }
  .summary {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: start;


    .card {
      padding: 2rem;
      width: 400px;
      border: 1px solid #e5e7eb;
      margin-top: 10px;
      .p{
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        border-bottom: 1px solid #e5e7eb;
        

      }
        
        p  span{
          color: red;


        }
      }
      .tax{
        padding: 2rem;
        
      }
      button{
        width: 100%;
        padding: 1.8rem;
        background-color: #000000;
        color: #fff;
      
       }
      
    }
  }

.count {
  display: flex;
  align-items: center;
  button {
    border: 1px solid var(--darkblue);
  }
  & > * {
    margin-right: 1rem;
  }
}



@media screen and (max-width: 768px) {
  .table{
    width: 90%;
  }
  table{
    width: 90%;
  }
  td{
    word-wrap: break-word;
  }
  .empt img{
    height: 150px;
    width: 150px;
  }
  h2{
    font-size: 1.5rem;
  }
  .summary {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    align-self: start;

    a{
      margin-bottom: 5px;
    }
  }
}
// cart 