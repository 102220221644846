.wrapper{
    width: 90%;
    margin: auto;


    h1{
        text-align: center;
        margin: 50px 0;
    }
}

@media screen and (max-width: 780px) {
    h1{
        text-align: center;
        margin: 20px 0;
    }

}
