.admin {
    display: flex;
    justify-content: space-between;
    
  
    .navbar {
      width: 15%;
      min-height: 80vh;
    }
    .content {
      width: 80%;
      padding: 1rem;
    }
  }