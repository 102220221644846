.payment-form {
    display: flex;
    flex-direction: column;

    h2{
margin-top: 20px;
    }
  
    .input-container {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      padding: 1.8rem;
  
      input[type='radio'] {
        margin-right: 5px;
        cursor: pointer;
      }
  
      label {
        margin-bottom: 5px;
        font-size: 1.5rem;
      }
  
      .dropdown {
        display: none;
        padding: 10px;
        border: 1px solid #ccc;

        border-radius: 5px;
        margin-top: 5px;


        .dropdown .paystack-dropdown{
            border: 1px solid #ccc;
            color: red;

        }
  
        img {
          max-width: 20px;
          margin-right: 10px;
        }
  
        p {
          margin: 0;

        }
      }
    }
  
    .input-container input[type='radio']:checked ~ .dropdown {
      display: block;


    }
  }
  