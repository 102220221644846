.form-container{
    max-width:500px;
    margin: 20px auto;

    .form-wrapper{
        margin-top: 50px;
        padding: 2rem;
        width: 100%;
        border-radius: 2px;
    }
     h2{
        font-size: 3rem;
        // list-style: 5px;
        font-weight: 500;
        padding-bottom: 1rem;
        text-align: center;
        color: #000;

    }
    p{
        margin-top: 5px;
        margin-bottom: 30px;
        font-size: 2rem;
    }

    form{
        padding: 30px 30px;
    }
    .form-control{
        padding: 1rem;
        position: relative;
    }
    
    .form-control input{
        display: block;
        width: 100%;
        font-size: 14px;
        outline: none;
        padding: 20px 20px;
        border: 2px solid #ccc;
    }
    .btn{
        display: block;
        font-size: 16px;
        outline: none;
        font-weight: 500;
        border: none;
        margin-top: 10px;
        text-align: center;
        align-items: center;
        border-radius: .8rem;
        width: 95%;
        padding: 1.8rem;
        background-color: #000000;
        color: #fff;
        cursor: pointer;
        margin-left: 10px;
      
    }
    p{
        text-align: center;
        margin-top:10px ;
        
    }
    p a{
        color: #000;
        text-decoration: underline;
    }
    .error{
        color:var(--color-danger);
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
    }
    .google-wrapper{
        width: 100%; 
        padding: 2rem;


    }
    .google{ 



    .google-btn {
        font-size: 14px;
        outline: none;
        font-weight: 500;      
      display: block;

        border: none;
        text-align: center;
        align-items: center;
        border-radius: .8rem;
        width: 95%;
        margin-left: 10px;
        padding: 1.8rem;
        background-color: #ddd;
        // color: #000;

span  .google-icon {
    background-color: linear-gradient(to right, #4285F4, #DB4437, #F4B400, #0F9D58);
  }


    }
      

    .facebook-btn{
        font-size: 14px;
        margin-top: 20px;
        outline: none;
        padding: 8px;
        background-color: blue;
        color: #fff;
        font-weight: 500;       
        display: block;
        width: 100%;
        border: none;
        text-align: center;
        align-items: center;
        border-radius: .8rem;
    }
    }
}

// media query 
@media screen and (max-width: 768px) {
    .form-container{
        // width: 300px;
        margin: 0 auto;
    }
    
}
