.fixed {
  width: 100%;
  position: fixed;
  top: 0;
  transition: all 0.5s;
  z-index: 9;
}

header {
  width: 100%;
  background-color: #fff;
  color: #000000;
  align-items: center;

  .header {
    width: 90%;
    height: 9rem;
    
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .logo a h2 {
    width: 25%;
    color: #000000;
    font-size: 3rem;
    font-weight: bold;
    font-family: "Avenir Next", sans-serif;
    margin: 0;

    cursor: pointer;

    span {}
  }

  .logo a img {
    width: 25%;
    color: #000000;
    font-weight: bold;
    margin: 0;

    cursor: pointer;

    span {}
  }



  nav {
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;

      .logo-mobile {
        display: none;
      }

      li {
        margin: 0 12px;

        a {
          color: #000000;
          font-size: 2rem;

          &:hover {
            // color: rgba(0, 0, 0, 0.7);
            color: #8224e3;
          }
        }
      }
    }
  }

  .header-right {
    display: flex;
    font-size: 2rem;
    align-items: center;

    .cart a {
      display: flex;
      color: #000000;
      font-size: 2rem;


      position: relative;

      &:hover {
        color: rgba(0, 0, 0, 0.7);
      }

      &.active {
        color: var(--color-danger);
      }

      p {
        position: absolute;
        top: -1rem;
        right: -1rem;
        font-weight: 500;
      }
    }

    span {
      margin: 0 5px;

      p {
        color: #000000;

      }
    }

    .links a {
      margin: 0 5px;
      font-size: 2rem;
      color: #000000;

      transition: 0.5s;

      &:hover {
        color: rgba(0, 0, 0, 0.7);

      }

      &.active {
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  .menu-icon {
    cursor: pointer;
    display: none;

  }

  @media screen and (max-width: 800px) {
    nav {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 70%;
      height: 100vh;
      background-color: #fff;
      padding: 1rem;
      z-index: 999;
      transform: translateX(-200%);
      transition: all 0.3s;

      .nav-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        transform: translateX();
        transition: all 0.3s;
      }

      .show-nav-wrapper {
        transform: translateX(100%);
      }

      ul {
        display: block;

        .logo-mobile {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: medium;
          margin-bottom: 10px;

          >* {
            cursor: pointer;
          }
        }

        li {
          margin: 5px 0;
          padding: 10px 0;


          a {
            display: block;
            font-size: 20px;
          }
        }
      }

      .header-right {
        display: flex;
        align-items: center;
        font-size: 20px;

        .cart {
          font-size: 20px;
        }

        .links {
          display: block;

          a {
            display: block;
            margin: 0;
            padding: 5px 0;

          }
        }
      }
    }

    .cart {
      display: block;
      padding: 5px 0;

      a {
        color: #000000;

        position: relative;

        &:hover {
          color: rgba(0, 0, 0, 0.7);
        }

        p {
          position: absolute;
          top: -1rem;
          left: 2rem;
          font-weight: 500;
          color: #000000;

        }
      }
    }

    .show-nav {
      transform: translateX(0);
    }

    .hide-nav {
      transform: translateX(-200%);
    }

    .cart p{
      margin-right: 6rem;
    }

    .menu-icon {
      display: flex;

      >* {
        margin-left: 3rem;
      }
    }
  }
}