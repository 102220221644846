.product-list {
    width: 100%;
    .top {
      width: 100%;
      // border: 2px solid #ccc;
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      background-color:  #f5f5f5;
      border-radius: .8rem;
      align-items: center;
      .icons {
        display: flex;
        justify-content: center;
        align-items: center;
        & > * {
          margin-right: 7px;
          cursor: pointer;
        }
      }
      
  
      .sort {
        label {
          font-size: 1.4rem;
          font-weight: 500;
          margin: 0 5px;
          color: orangered;
        }
        select {
          font-size: 1.6rem;
          font-weight: 300;
          border: none;
          // border-bottom: 1px solid #777;
          outline: none;
        }
      }
    }
  
    @media screen and (max-width: 800px) {
      .top {
        flex-direction: column;
        align-items: start;
        padding-bottom: 5px;
      }
      .search {
        width: 100%;
      }
    }
  }
  
  .grid {
    display: flex;
    justify-content: space-around;
  
    flex-wrap: wrap;
    // padding: 1rem 0;
    background-color: #fffdf7;
  }