.checkout{
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      gap: 20px;

      h1{
        margin-bottom: 20px;
        font-weight: bold;
      }
      h4{
        margin-bottom: 20px;
      }

      h3{
        margin-top:50px;
      }


          form {
      width: 100%;
      margin: 0 auto;
      display: flex;


      .fullinput input{
        width: 100%;
        padding: 1.8rem;
        border-radius: .8rem;
        outline: none;
        border: 3px solid #eeee;

        margin-bottom: 15px;

        ;



      }
      .fullinput select{
        width: 100%;
        padding: 1.8rem;
        border-radius: .8rem;
        outline: none;
        border: 3px solid #eeee;
        margin-bottom: 15px;

      }

      .inputfex{
        display: flex;
        gap: 20px;

      }
      .inputfex input {
        width: 100%;
        padding: 1.8rem;
        border-radius: .8rem;
        outline: none;
        border: 3px solid #eeee;

        margin-bottom: 15px;

        
      }
      
      div {
        width: 100%;
      }


 }


 .paymentform {
  display: flex;
  flex-direction: column;

  .input-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;

    label {
      margin-right: 10px;
    }

    input[type='radio'] {
      margin-right: 5px;
      cursor: pointer;
    }

    .dropdown {
      display: none;
      background-color: #f9f9f9;
      padding: 10px;
      border-radius: 5px;

      img {
        max-width: 20px;
        margin-right: 10px;
      }

      p {
        margin: 0;
      }
    }
  }

  .input-container input[type='radio']:checked ~ .dropdown {
    display: block;
  }
}


 button{
  width: 100%;
  padding: 1.8rem;
  background-color: #000000;
  color: #fff;

 }



}

  @media screen and (max-width: 700px) {
    .checkout {
      form {
        flex-direction: column;
        div {
          width: 100%;
        }
      }
    }
  }
