.whatsapp_float {

    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 80px;
    right: 15px;
    background-color: #222222;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    animation: shake 2s infinite;
}

.whatsapp-icon {
    margin-top: 16px;
}

.help-text {
    position: fixed;
    bottom: 120px;
    right: 70px;
    background-color: #f5f7f9;
    ;
    color: #000;
    padding: 12px 18px;
    border-radius: 4px;
    font-size: 1.5rem;
    z-index: 99;
    
    transition: transform 0.3s ease-in-out;
    transform: translateX(150%);
}

.whatsapp_float:hover+.help-text {
    display: block;

}

.whatsapp_float:hover+.help-text,
.help-text:hover {
    display: block;
    transform: translateX(-10%);
}


/* for mobile */
@media screen and (max-width: 767px) {
    .whatsapp-icon {
        margin-top: 10px;
    }

    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 10px;
        font-size: 22px;
    }

    .help-text {
        bottom: 28px;
        /* Adjust positioning for mobile */
        right: 60px;
        /* Adjust positioning for mobile */
        font-size: 12px;
        /* Adjust font size for mobile */
    }
}


@keyframes shake {

    0%,
    100% {
        transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-5px);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateX(5px);
    }
}