.order {
    padding: 2rem;
    width: 100%;
    overflow-x: auto;
  
    .text {
      margin-top: 100px;
      align-items: center;
      display: flex;
      flex-direction: column;
      align-items: center; /* Horizontally center the content */
      justify-content: center; /* Vertically center the content */
  
    }
  
    table {
      border-collapse: collapse;
      width: 100%;
      font-size: 1.4rem;
  
      thead {
        border-top: 2px solid var(--light-blue);
        border-bottom: 2px solid var(--light-blue);
      }
  
      th {
        border: 1px solid #eee;
      }
  
      th,
      td {
        vertical-align: top;
        text-align: left;
        padding: 8px;
        &.icons {
          > * {
            margin-right: 5px;
            cursor: pointer;
          }
        }
      }
  
      tr {
        border-bottom: 1px solid #ccc;
        cursor: pointer;
      }
  
      tr:nth-child(even) {
        background-color: #eee;
      }
      .pending {
        color: orangered;
        font-weight: 500;
      }
      .delivered {
        color: green;
        font-weight: 500;
      }
    }
  }
  @media screen and (max-width: 600px){
    .table{
      th {
        display: flex;
        flex-direction: column;
      }
      tr {
        cursor: pointer;
        display: flex;
        flex-direction: column;
      }
    }
  }