/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');


:root {
  --font-family: "Manrope";
  --dark-blue: #0a1930;
  --light-blue: #1f93ff;

  --color-white: #fff;
  --color-dark: #333;

  --color-grey: #eee;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  --color-purple: #9d0191;
  --color-orange: #ff7722;

  --color-primary: #007bff;
  --color-success: #28a745;
  --color-danger: orangered;
  --color-red : red ;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-size: 10px;
}
body{
  font-size: 6.5px;
  font-family: Lato, Roboto, 'Courier New', Courier, monospace;
}


section {
  width: 100%;
  /* padding: 2rem 0; */
}

.container {
  /* max-width: 1000px; */
  margin:  auto;

}

/* UTILITY CLASSES */

/* Flex */
.--flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.--flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.--flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.--flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.--dir-column {
  flex-direction: column;
}

.--flex-dir-column {
  display: flex;
}

.--align-center {
  display: flex;
  align-items: center;
}

.--100vh {
  height: 100vh;
}

.--mh-100vh {
  min-height: 100vh;
}

/* Grid */
.--grid-15 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  row-gap: 1rem;
  column-gap: 1rem;
}
.--grid-25 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  row-gap: 1rem;
  column-gap: 1rem;
}

/* Center All */
.--center-all {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: auto;
  text-align: center;
}

/* Heading */
h1,
h2,
h3,
h4 {
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-dark);
  margin-bottom: 1rem;
}
h1 {
  font-size: 4rem;
}
h2 {
  font-size: 3rem;
}
h3 {
  font-size: 2.5rem;
}
h4 {
  font-size: 2rem;
}

p {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.3;
  color: var(--color-dark);
}
.--text-xl {
  font-size: 4.5rem;
}
.--text-lg {
  font-size: 4rem;
}

.--text-md {
  font-size: 3rem;
}

.--text-sm {
  font-size: 1.2rem;
  font-weight: 300;
}

.--text-p {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.3;
  color: var(--color-dark);
}

.--fw-bold {
  font-weight: 600;
}
.--fw-thin {
  font-weight: 200;
}

/* Text Color */
.--text-light {
  color: #fff;
}

.--color-primary {
  color: #007bff;
}
.--color-danger {
  color: orangered;
}
.--color-success {
  color: #28a745;
}

.--color-white {
  color: #fff;
}

/* Center Text */
.--text-center {
  text-align: center;
}

/* Card */
.--card {
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  overflow: hidden;
}

/* Margin */
.--m {
  margin: 1rem;
}
.--ml {
  margin-left: 1rem;
}
.--mr {
  margin-right: 1rem;
}

.--mb {
  margin-bottom: 1rem;
}

.--my {
  margin: 1rem 0;
}
.--mx {
  margin: 0 1rem;
}

.--m2 {
  margin: 2rem;
}

.--ml2 {
  margin-left: 2rem;
}
.--mr2 {
  margin-right: 2rem;
}

.--mb2 {
  margin-bottom: 2rem;
}

.--my2 {
  margin: 2rem 0;
}

.--mx2 {
  margin: 0 2rem;
}

/* Padding */
.--p {
  padding: 1rem;
}
.--p2 {
  padding: 2rem;
}
.--py {
  padding: 1rem 0;
}
.--py2 {
  padding: 2rem 0;
}
.--px {
  padding: 0 1rem;
}
.--px2 {
  padding: 0 2rem;
}

.--btn {
  font-size: 1.6rem;
  font-weight: 400;
  padding: 6px 8px;
  margin: 0 5px 0 0;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

/* .--btn:hover {
  transform: translateY(-2px);
} */

.--btn-lg {
  padding: 8px 10px;
}

.--btn-block {
  width: 100%;
}

.--btn-primary {
  color: #fff;
  background: #007bff;
}
.--btn-secondary {
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
}
.--btn-danger {
  color: #fff;
  background: orangered;
}

.--btn-danger-red {
  color: #fff;
  background: red;
}


.--btn-success {
  color: #fff;
  background: #28a745;
}

/* Background */
.--bg-light {
  background: #fff;
}
.--bg-dark {
  background: var(--color-dark);
}
.--bg-primary {
  background: var(--color-primary);
}
.--bg-success {
  background: var(--color-success);
}
.--bg-grey {
  background: var(--color-grey);
}

.--form-control {
  font-size: 1.6rem;
  font-weight: 300;
}

.--form-control > * {
  margin: 5px 0;
}

.--form-control input {
  font-size: 1.6rem;
  font-weight: 300;
  padding: 8px 1rem;
  border: 1px solid #777;
  border-radius: 3px;
  outline: none;
}
.--form-control select {
  font-size: 1.4rem;
  font-weight: 400;
  padding: 8px 1rem;
  border: 1px solid #777;
  border-radius: 3px;
}

.--form-control label {
  font-size: 1.6rem;
  font-weight: 400;
  display: inline-block;
  min-width: 7rem;
  color: var(--color-dark);
  margin-right: 1rem;
}

@media screen and (max-width: 600px) {
  .--flex-dir-column {
    flex-direction: column;
  }
}

.--block {
  display: block;
}
.--inline-block {
  display: inline-block;
}

.--width-100 {
  width: 100%;
}

.--width-500px {
  width: 500px;
}

.--line {
  position: relative;
}
.--line::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  width: 5rem;
  height: 3px;
  margin-bottom: 1rem;

  background: rgb(217, 8, 170);
  background: linear-gradient(
    135deg,
    rgba(163, 1, 191, 1) 44%,
    rgba(217, 8, 170, 1) 57%
  );
}

.--list-style-none {
  list-style: none;
}

.--profile-img {
  width: 6rem;
  height: 6rem;
  border: 1px solid #ccc;
  border-radius: 50%;
}

a {
  font-size: 1.6rem;
  color: var(--dark-blue);
  text-decoration: none;
  transition: all 0.2s;
}

/* a:hover {
  color: var(--color-dark);
  font-size: 1.5rem;
} */


.myBlackButton {
  background-color: #000000;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #333333;
  }

  &:active {
    transform: translateY(2px);
  }

  &:after {
    background: #fff;
    content: "";
    height: 155px;
    left: -75px;
    opacity: .2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: -10;
  }

  @media screen and (max-width: 600px) {
    padding: 8px 16px; 
    font-size: 14px; 
  }
}


.btn-4 {
  border: 1px solid;
  overflow: hidden;
  position: relative;
  
  span {
    z-index: 20;
  }
  
  &:after {
    background: #fff;
    content: "";
    height: 155px;
    left: -75px;
    opacity: .2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: -10;
  }
}

.btn-4:hover {
  
  &:after {
    left: 120%;
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  }
}
