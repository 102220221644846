.form-container{
    width: 100%;
    max-width: 500px;
    padding: 1rem;
    margin-right: 30px;
    .form-wrapper{
        margin-top: 50px;
        padding: 2rem;
        width: 100%;
        border-radius: 2px;
        background-color: hsla(0, 0%, 100%, 1);
        box-shadow: 1px 0px 8px rgba( 0, 0, 0, 0.15);
    }
     h2{
        font-size: 3rem;
        list-style: 5px;
        font-weight: 100;
        padding-bottom: 1rem;
        text-align: center;
        color: orangered;

    }
    form{
        padding: 30px 30px;

        label {
            display: block;
            font-size: 1.4rem;
            font-weight: 500;
            color: #222;
          }

          input[type="text"],
          input[type="number"],
          input[type="file"],
          input[type="email"],
          select,
          textarea,
          input[type="password"] {
            display: block;
            font-size: 1.6rem;
            font-weight: 300;
            padding: 1rem;
            margin: 1rem auto;
            width: 100%;
            border: 1px solid #777;
            // background-color: #f2f2f2;
            border-radius: 3px;
            background-color: -internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;
            outline: none;
          }

          .group {
            border: 1px solid var(--dark-blue);
            padding: 5px;
          }

          .progress {
            background-color: #aaa;
            border: 1px solid transparent;
            border-radius: 10px;
            .progress-bar {
              background-color: var(--light-blue);
              border: 1px solid transparent;
              border-radius: 10px;
              color: #fff;
              font-size: 1.2rem;
              font-weight: 500;
              padding: 0 1rem;
            }
          }
    
      
    
    
    }
    
    
        .btn{
        display: block;
        width: 100%;
        font-size: 16px;
        outline: none;
        padding: 8px;
        background-color: blue;
        color: #fff;
        font-weight: 500;
        border: none;
        margin-top: 10px;
        text-align: center;
        align-items: center;
        border-radius: .8rem;
    }

    p{
        text-align: center;
        margin-top:10px ;
        
    }
    p a{
        color: orangered;
    }
    .error{
        color:var(--color-danger);
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
    }
   
}

// media query 
@media screen and (max-width: 768px) {
    .form-container{
        width: 300px;
        margin: 0 auto;
    }
    
}




