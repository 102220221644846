.section{
    width: 70%;
    text-align: center;
    margin: 20px auto;

.main{
    align-items: center;
    justify-content: center;

    a button {
      background-color: #2752db;
      padding: .8rem 1.5rem;
      border: none;
      margin-top: 20px;
      outline: none;
      border-radius: .8rem 0rem .8rem .8rem;
      color: #fff;


    }
}

  }
