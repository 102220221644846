.product {
  max-width: 90%;
  margin: 20px auto;
  .titlehead{
    display: flex;
    justify-content: space-between;
  

  }
  .card {
    padding: 1rem;
    margin-top: 1rem;
  }
  .review {
    border-top: 1px solid #ccc;
  }
  .details {
    padding-top: 2rem;
    display: flex;
    gap: 200px;



    .img {
      width: 45%;
      border-radius: 3px;
      img {
        width: 100%;
      }
    }
    .title{
      width: 65%;
    }

    .title h3{
      font-size: 3rem;
      font-weight: bold;
      font-family: sans-serif;

    }

   .title .sizesContainer {
      display: flex;
      gap: 8px; 
    }
    
    .size {
      display: inline-block;
      margin-right: 5px; 
      padding: 2rem; 
      border: 2px solid #eee;
      background-color: #fff; 
      color: #000; 
      font-size: 1.5rem;
        }
    
    .selected {
      background-color: #00f; 
      color: #fff; 
    }
        
    
        .title p{
          font-size: 2rem;
          font-family: sans-serif;
          margin-bottom: 20px;

        }

    .content {
      width: 65%;
      padding: 0 5px;
      & > * {
        margin-bottom: 1rem;
      }
      .price {
        color: orangered;
        font-weight: 500;
      }



    }
    .count {
      display: flex;
      align-items: center;
    
      button {
        padding: 5px 10px;
      }
    
      p {
        margin: 0 10px;
        font-weight: bold;
      }
    }

     .addtocart{
      width: 100%;
      padding: 1.8rem;
      background-color: #000000;
      color: #fff;
      margin-top: 20px;
    
    }

  }
}

@media screen and (max-width: 768px) {
  .product {
    .details {
      flex-direction: column;
      gap: 50px;


      .img {
        width: 100%;
      }
      .title{
        width: 90%;
      }
  
      .title h3{
        font-size: 2rem;
        font-weight: bold;
        font-family: sans-serif;
  
      }
  
  

      .content {
        width: 100%;
      }
    }
  }
}
