.buttons{
    width: 100%;
    max-width: 400px;
    padding: 1rem;
    margin: 0 auto;

    .paystack{
        width: 100%;
        padding: 1rem;
        margin-top: 10px;
        border: none;
        color: #fff;
        background-color: var(--light-blue);
        width: 100%;
        padding: 1.8rem;
        background-color: #000000;
        color: #fff;
      
    }   
     .flutterwave{
        width: 100%;
        padding: 1rem;
        margin-top: 10px;
        border: none;
        color: #fff;
        background-color: orangered;
    }




}