.contact {
    width: 100%;
    // height: 80%;
    min-height: 80vh;

    padding: 14rem 0;
    background-image: url('/images/5.jpg'); // Replace 'path/to/your/image.jpg' with the actual path to your image
    background-size: cover;
    background-position: center;
    color: #fff; // Set text color to ensure it's readable on the background image

    .title{
        margin-top: 150px;
    }
    h1 {
        margin-top: 100px;
        font-size: 4rem;
        font-weight: bold;
        color: #fff;
        width: 90%;
        margin: auto;
        text-align: center;
        padding-bottom: 2rem;
    }

    p {
        font-size: 2rem;
        line-height: 3rem;
        color: #fff;
        width: 90%;
        margin: auto;
        text-align: center;
    }
}



@media screen and (max-width: 767px) {
    .contact {

        h1 {
            font-size: 2rem;

        }

        p {
            font-size: 1rem;

        }
    }
}