.categories {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem , 1fr));
  row-gap: 1rem;
  column-gap: 1rem;
  margin-top: 20px;


}

.category {
  position: relative;
  height: 80rem;
  margin-right: 5px;
  margin-bottom: 5px;
  overflow: hidden;
  border-radius: 3px;

  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
    transition: filter 0.3s ease;

    &:hover {
      filter: grayscale(100%);
    }
  }

  &:hover {
    .category-content {
      opacity: 1;
    }
  }

  .category-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 1; 
    color: white;

    h3 {
      margin-bottom: 0.5rem;
      color: #fff;
    }

    button {
      background: #fff;
      border: 1px solid #fff;
      color: #000000;
      padding: 0.5rem 1rem;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
}


@media screen and (max-width: 780px) {
  .categories {
    grid-auto-columns: 55%;
    grid-auto-flow: column;
    grid-gap: 16px;
    overflow-x: auto;
  
  }
  .category {
    height: 40rem;
  }
  
}
